import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import yerlem from "../assets/yerlem.png";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import toast from "react-hot-toast";
import { appConfig } from "../constant/appconfig";
const StyledTextField = styled(TextField)({
  marginBottom: "1rem",
  "& .MuiInputLabel-root": {
    color: "#555",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
  },
});

const StyledButton = styled(Button)({
  backgroundColor: "#3f51b5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#303f9f",
  },
  borderRadius: "25px",
  padding: "0.75rem 1.5rem",
});
// const schools = [
//   { label: "School 1", id: 1, coords: { lat: 37.9683, lng: 32.4987 } },
//   { label: "School 2", id: 2, coords: { lat: 37.9611, lng: 32.4928 } },
//   { label: "School 3", id: 3, coords: { lat: 37.9652, lng: 32.4956 } },
// ];

const isValidTCKimlik = (tcKimlikNo) => {
  if (tcKimlikNo.length !== 11) return false;

  let ATCNO = parseInt(tcKimlikNo.substring(0, 9));
  let C1 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C2 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C3 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C4 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C5 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C6 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C7 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C8 = ATCNO % 10;
  ATCNO = Math.floor(ATCNO / 10);
  let C9 = ATCNO % 10;

  let Q1 =
    (10 - (((C1 + C3 + C5 + C7 + C9) * 3 + (C2 + C4 + C6 + C8)) % 10)) % 10;
  let Q2 =
    (10 - (((C2 + C4 + C6 + C8 + Q1) * 3 + (C1 + C3 + C5 + C7 + C9)) % 10)) %
    10;

  return (
    parseInt(tcKimlikNo) === parseInt(tcKimlikNo.substring(0, 9) + Q1 + Q2)
  );
};

const schema = z.object({
  firstname: z
    .string({ required_error: "İsim alanı boş bırakılamaz" })
    .min(1, { message: "İsim gereklidir" }),
  lastname: z
    .string({ required_error: "Soyisim boş bırakılamaz" })
    .min(1, { message: "Soyisim gereklidir" }),
  tc: z
    .string({ required_error: "TC No 11 haneli olmalıdır" })
    .min(11, { message: "TC No 11 haneli olmalıdır" })
    .max(11, { message: "TC No 11 haneli olmalıdır" })
    .regex(/^[1-9]\d{10}$/, {
      message: "Geçerli bir TC No girin (İçeriğinde harf olmamalı)",
    })
    .refine((value) => isValidTCKimlik(value), {
      message: "Geçerli bir TC No değil",
    }),
  phoneNumber: z
    .string({ required_error: "Telefon No 10 haneli olmalıdır" })
    .min(1, { message: "Telefon No 10 haneli olmalıdır" })
    .max(10, { message: "Telefon No 10 haneli olmalıdır" })
    .regex(/^[1-9]\d{9}$/, {
      message:
        "Geçerli bir Telefon No girin (Başında 0 ve içeriğinde harf olmamalı)",
    }),
  schoolId: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .refine((data) => data.name && data.id, {
      message: "Bir okul seçmelisiniz.",
    }),
  userAddress: z
    .string({ required_error: "Adres alanı boş bırakılamaz" })
    .min(15, { message: "Adres alanı en az 15 karakter olmalıdır" }),
});

const RegisterForm = () => {
  const [step, setStep] = useState(1);
  const [position, setPosition] = useState([37.872257, 32.492023]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSchoolDistance, setSelectedSchoolDistance] = useState(null);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [price, setPrice] = useState({});
  const [outOfService, setOutOfService] = useState(false);
  const [notFoundPrice, setNotFoundPrice] = useState(false);
  const [student, setStudent] = useState(null);
  const [address, setAddress] = useState({
    fullAddress: "",
    neighborhood: "",
    district: "",
  });
  const [formState, setFormState] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const calculateDistance = async (userCoords, schoolCoords) => {
    console.log(userCoords);
    let requestBody = {};
    console.log(schoolCoords);
    const ArraySchoolCoords = schoolCoords?.split(", ");
    if (Array.isArray(userCoords)) {
      requestBody = {
        points: [
          [userCoords[1], userCoords[0]],
          [ArraySchoolCoords[1], ArraySchoolCoords[0]],
        ],
        snap_preventions: ["motorway", "ferry", "tunnel"],
        details: ["road_class", "surface"],
        profile: "car",
        locale: "en",
        instructions: true,
        calc_points: true,
        points_encoded: false,
      };
    } else {
      requestBody = {
        points: [
          [userCoords.lng, userCoords.lat],
          [ArraySchoolCoords[1], ArraySchoolCoords[0]],
        ],
        snap_preventions: ["motorway", "ferry", "tunnel"],
        details: ["road_class", "surface"],
        profile: "car",
        locale: "en",
        instructions: true,
        calc_points: true,
        points_encoded: false,
      };
    }

    try {
      const response = await fetch(
        "https://graphhopper.com/api/1/route?key=5b01da8f-832d-47f0-ac3f-d0eb4b2223d8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.message) {
        console.error("Mesafe hesaplanamadı:", data.message);
        setOutOfService(true);
        return "Mesafe Hesaplanamadı";
      }
      const distance = data.paths[0].distance;
      return distance;
    } catch (error) {
      console.error("Mesafe hesaplanamadı:", error);
      return null;
    }
  };
  const resetAllStates = () => {
    setStep(1);
    setPosition([37.872257, 32.492023]);
    setOpenModal(false);
    setSelectedSchoolDistance(null);
    setSelectedSchool(null);
    setPrice({});
    setAddress({
      fullAddress: "",
      neighborhood: "",
      district: "",
    });
    setStudent(null);
    setOutOfService(false);
    setNotFoundPrice(false);
    reset();
    setFormState(null);
  };
  const fetchSchools = async () => {
    try {
      const response = await fetch(appConfig.baseUrl + "school/getAll");
      const data = await response.json();
      console.log(data);
      const schoolsFilterHasCoordinate = data.filter(
        (school) => school.coordinate !== ""
      );
      console.log(schoolsFilterHasCoordinate);
      setSchools(schoolsFilterHasCoordinate);
    } catch (error) {
      console.error("Okullar alınamadı:", error);
      setSchools([]);
    }
  };
  const getSchoolPrice = async (distance) => {
    const searchParams = new URLSearchParams();
    searchParams.append("distance", distance / 1000);
    if (selectedSchoolDistance === null) return;
    searchParams.append("schoolId", selectedSchool?.id);
    try {
      const response = await fetch(
        appConfig.baseUrl + `price/getPrice?${searchParams.toString()}`
      );
      const data = await response.json();
      if (response.status === 500) {
        setPrice({});
        if (data.message === "Verilen km için servis yok") {
          setOutOfService(true);
        }
        if (data.message === "Verilen km aralığında bir fiyat yok") {
          setOutOfService(false);
          setNotFoundPrice(true);
        }
        throw new Error();
      }
      setOutOfService(false);
      setNotFoundPrice(false);
      setPrice(data);
    } catch (error) {
      console.error("Fiyat alınamadı:", error);
      setPrice({});
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
    fetchSchools();

    if (position) {
      fetchAddress(position[0], position[1]);
      if (selectedSchool) {
        getSchoolPrice();
      }
    }
  }, []);
  const handleSchoolSelection = async () => {
    if (!selectedSchool) {
      console.error("Seçili okul bulunamadı");
      return;
    }

    const distance = await calculateDistance(
      position,
      selectedSchool.coordinate
    );

    if (distance !== null) {
      setSelectedSchoolDistance(distance / 1000);
      return distance;
    } else {
      console.error("Mesafe hesaplanamadı");
    }
  };

  const onSubmit = async (data) => {
    setFormState(data);
    try {
      await handleSchoolSelection();
      setStep(2);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFinish = () => {
    getStudent();
    setOpenModal(true);
  };

  const saveStudent = async () => {
    try {
      const response = await fetch(appConfig.baseUrl + "student/saveStudent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          outOfService || notFoundPrice
            ? {
                ...formState,
                address: address.fullAddress,
                coordinate: position[0] + ", " + position[1],
                neighbourhood: address.neighborhood,
                town: address.district,
                distance: selectedSchoolDistance,
              }
            : {
                ...formState,
                address: address.fullAddress,
                coordinate: position[0] + ", " + position[1],
                neighbourhood: address.neighborhood,
                town: address.district,
                priceId: price,
                distance: selectedSchoolDistance,
              }
        ),
      });
      const data = await response.json();
      if (response.status === 500) {
        throw new Error(data.message);
      }
      console.log("Kayıt başarılı:", data);
      toast.success("Kayıt başarılı", {
        duration: 5000,
        style: { padding: "1rem" },
      });
      resetAllStates();
    } catch (error) {
      toast.error("Kayıt başarısız", {
        duration: 5000,
        style: { padding: "1rem" },
      });
      console.error("Kayıt başarısız:", error);
    }
  };
  const updateStudent = async () => {
    try {
      const response = await fetch(
        appConfig.baseUrl + "student/updateStudent",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...student,
            ...formState,
            address: address.fullAddress,
            coordinate: position[0] + ", " + position[1],
            neighbourhood: address.neighborhood,
            town: address.district,
            priceId: price,
            distance: selectedSchoolDistance,
          }),
        }
      );
      const data = await response.json();
      toast.success("Güncelleme başarılı", {
        duration: 5000,
        style: { padding: "1rem" },
      });

      console.log("Güncelleme başarılı:", data);
      resetAllStates();
    } catch (error) {
      toast.error("Güncelleme başarısız", {
        duration: 5000,
        style: { padding: "1rem" },
      });
      console.error("Güncelleme başarısız:", error);
    }
  };
  const getStudent = async () => {
    try {
      const response = await fetch(
        appConfig.baseUrl +
          "student/findStudentByTcNumber?tcNumber=" +
          formState.tc,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setStudent(data);
      console.log("Öğrenci bilgileri:", data);
    } catch (error) {
      setStudent(null);
      console.error("Öğrenci bilgileri alınamadı:", error);
    }
  };

  const confirmAddress = async () => {
    if (student) {
      await updateStudent();
      resetAllStates();
      setOpenModal(false);
    } else {
      await saveStudent();
      resetAllStates();
      setOpenModal(false);
    }
  };

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
      );
      const data = await response.json();

      const fullAddress = data.display_name;
      const neighborhood = data.address?.suburb || "Mahalle bilgisi bulunamadı";
      const district =
        data.address?.town || data.address?.city || "İlçe bilgisi bulunamadı";

      setAddress({ fullAddress, neighborhood, district });
    } catch (error) {
      console.error("Adres alınamadı:", error);
    }
  };
  useEffect(() => {
    fetchAddress(position[0], position[1]);
    handleSchoolSelection().then((distance) => {
      getSchoolPrice(distance);
    });
  }, [position, selectedSchool]);

  const LocationMarker = () => {
    useMapEvents({
      async click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
      },
    });

    return position === null ? null : (
      <Marker
        position={position}
        icon={
          new L.icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          })
        }
      ></Marker>
    );
  };

  return (
    <div className="flex h-screen w-screen flex-col lg:flex-row items-center justify-center p-2">
      {step === 1 && (
        <div className="w-full lg:w-3/5 lg:border-2 flex flex-col justify-center items-center lg:flex-row h-auto lg:h-[700px] lg:rounded-lg lg:shadow-xl">
          <div
            className="w-full lg:w-1/2 h-[160px] lg:h-full flex items-center justify-center bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${yerlem})` }}
          ></div>

          <div className="w-full lg:w-1/2 flex items-center justify-center p-3 bg-white">
            <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="text-3xl text-center font-bold mb-4">
                Yerlem Öğrenci Kayıt Sistemi
              </h1>

              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
                <Controller
                  name="firstname"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      label="İsim"
                      error={!!errors.firstname}
                      helperText={errors.firstname?.message || ""}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      label="Soyisim"
                      error={!!errors.lastname}
                      helperText={errors.lastname?.message || ""}
                      fullWidth
                    />
                  )}
                />
              </div>

              <Controller
                name="tc"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    label="TC No"
                    error={!!errors.tc}
                    helperText={errors.tc?.message || ""}
                    fullWidth
                    inputProps={{ maxLength: 11 }}
                  />
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    label="Telefon No"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message || ""}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+90</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
              <Controller
                name="userAddress"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    label="Adres"
                    error={!!errors.userAddress}
                    helperText={errors.userAddress?.message || ""}
                    fullWidth
                    InputProps={{
                      multiline: true,
                      rows: 3,
                    }}
                    inputProps={{ minLength: 15 }}
                  />
                )}
              />

              <Controller
                name="schoolId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={schools}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      field.onChange(value);
                      setSelectedSchool(value);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        label="Okul Seç"
                        error={!!errors.schoolId}
                        helperText={errors.schoolId?.message || ""}
                        fullWidth
                      />
                    )}
                  />
                )}
              />

              <StyledButton variant="contained" type="submit" fullWidth>
                İlerle
              </StyledButton>
            </form>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="w-full lg:w-3/4 border-2 flex flex-col justify-center items-center h-[500px] rounded-lg shadow-xl p-4">
          <h1 className="text-3xl font-bold mb-4">Konum Seç</h1>
          <MapContainer
            attributionControl={false}
            center={position}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "300px", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <LocationMarker />
          </MapContainer>

          <div className="mt-4 w-full">
            <p>
              {address.neighborhood ? `Mahalle: ${address.neighborhood}` : ""}
            </p>
            <p>{address.district ? `İlçe: ${address.district}` : ""}</p>
            {selectedSchoolDistance !== null && (
              <p>
                Seçili okul ile mesafe:{" "}
                {isNaN(selectedSchoolDistance)
                  ? "Mesafe Hesaplanamadı"
                  : selectedSchoolDistance.toFixed(2) + " km"}
              </p>
            )}
            {/* {<p>Address: {formState?.userAddress}</p>} */}
            {price.price !== 0 && !notFoundPrice && (
              <p>Seçili Konuma Göre Fiyat: {price.price} TL</p>
            )}
            {outOfService && (
              <p className="text-red-500">
                Bu mesafe için hizmet veremiyoruz. Kaydı tamamladığınız takdirde
                servis firması sizinle iletişime geçecektir.
              </p>
            )}
            {notFoundPrice && (
              <p className="text-red-500">
                Bu mesafe için fiyat bulunamadı. Kaydı tamamladığınız takdirde
                servis firması sizinle iletişime geçecektir.
              </p>
            )}
          </div>

          <div className="flex space-x-4 mt-4">
            <StyledButton variant="outlined" onClick={() => setStep(1)}>
              Geri
            </StyledButton>
            <StyledButton variant="contained" onClick={handleFinish}>
              Bitir
            </StyledButton>
          </div>
        </div>
      )}

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bilgi Onayı"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kaydı yapılacak bilgiler:
            <ul>
              <li>
                <strong>Ad Soyad:</strong> {formState?.firstname}{" "}
                {formState?.lastname}
              </li>
              <li>
                <strong>TC No:</strong> {formState?.tc}
              </li>
              <li>
                <strong>Telefon No:</strong> {formState?.phoneNumber}
              </li>
              <li>
                <strong>Okul:</strong> {formState?.schoolId.name}
              </li>
              <li>
                <strong>Mahalle:</strong> {address?.neighborhood}
              </li>
              <li>
                <strong>İlçe:</strong> {address?.district}
              </li>
              <li>
                <strong>Adres:</strong> {formState?.userAddress}
              </li>
              <li>
                <li>
                  {!notFoundPrice ? (
                    <>
                      <strong>Seçili Konuma Göre Fiyat</strong>:{" "}
                      <span> {price.price + " TL"}</span>
                    </>
                  ) : (
                    <>
                      <strong>Seçili Konuma Göre Fiyat</strong>:{" "}
                      <span className="text-red-500">
                        Fiyat bilgisi bulunamamaktadır. Onay verdiğiniz takdirde
                        servis firması sizinle iletişime geçecektir.
                      </span>
                    </>
                  )}
                </li>
                <strong>Seçili Okul ile Mesafe:</strong>{" "}
                {/* {(selectedSchoolDistance / 1000).toFixed(2)} km */}
                {selectedSchoolDistance !== null && (
                  <span>
                    {isNaN(selectedSchoolDistance)
                      ? "Mesafe Hesaplanamadı"
                      : selectedSchoolDistance.toFixed(2) + " km"}
                  </span>
                )}
              </li>
            </ul>
            <b>Kaydı tamamlamak istediğinize emin misiniz?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Hayır
          </Button>
          <Button onClick={confirmAddress} color="primary" autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default RegisterForm;
